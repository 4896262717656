export interface TenantStorageObject {
  id: number;
  alias: string;
  branchId: string;
  regionId: string;
  zoneId: string;
  name?: string;
  theme?: string;
}

const localStorageTenantKey = "rc_tenantStorage_tenant";
const localStorageLanguageKey = "rc_tenantStorage_language";
const localStorageUserProfile = "rc_tenantStorage_userProfile";

export class TenantStorage {
  setTenant(tenant: TenantStorageObject): void {
    if (tenant.branchId && tenant.regionId && tenant.zoneId) {
      localStorage.setItem(localStorageTenantKey, JSON.stringify(tenant));
    } else {
      localStorage.setItem(localStorageTenantKey, JSON.stringify(
        { 
          alias: tenant.alias,
          theme: tenant.theme
         }
      ));
    }
  }

  getTenant(): TenantStorageObject | null {
    const data = localStorage.getItem(localStorageTenantKey);
    if (!data) return null;
    return JSON.parse(data);
  }

  removeTenant(): void {
    localStorage.removeItem(localStorageTenantKey);
  }

  setLanguage(language: string): void {
    localStorage.setItem(localStorageLanguageKey, language);
  }

  getLanguage(): string {
    const data = localStorage.getItem(localStorageLanguageKey);
    if (!data) return "";
    return data;
  }

  removeLanguage(): void {
    localStorage.removeItem(localStorageLanguageKey);
  }

  setUserProfile(userProfile: string): void {
    localStorage.setItem(localStorageUserProfile, userProfile);
  }

  getUserProfile(): string {
    const data = localStorage.getItem(localStorageUserProfile);
    if (!data) return "";
    return data;
  }

  removeUserProfile(): void {
    localStorage.removeItem(localStorageUserProfile);
  }
}
